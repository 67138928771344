import React from "react";
import PropTypes from "prop-types";

import { Section, Button, Container, Title, Text, Markdown, getProperty } from "@btc/shared";

import * as styles from './attestation.module.scss';

export const Attestation = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const buttonLabel = getProperty(data, "button.label");
  const buttonURL = getProperty(data, "button.file.publicURL");

  return buttonURL ? (
    <Section color="black" size="md">
      <Container>
        <Title>{title}</Title>
        <Text>
          <Markdown content={content} />
        </Text>
        <Button className={styles.button} type="border" color="primary" wrapper="a" href={buttonURL} download={title}>
          {buttonLabel}
        </Button>
      </Container>
    </Section>
  ): null;
};

Attestation.defaultProps = {
  data: {},
};

Attestation.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    button: PropTypes.shape({
      label: PropTypes.string,
      file: PropTypes.object
    }),
  }),
};
