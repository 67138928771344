import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@btc/shared";

import { Teaser } from "./tickets/teaser";
import { Widget } from "./tickets/widget";
import { Facts } from "./tickets/facts";
import { Table } from "./tickets/table";
import { Attestation } from "./tickets/attestation";
import { Meta } from "./common/meta";

const Template = ({ data }) => {
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const teaser = getProperty(data, "markdownRemark.frontmatter.tickets.teaser");
  const widget = getProperty(data, "markdownRemark.frontmatter.tickets.widget");
  const facts = getProperty(data, "markdownRemark.frontmatter.tickets.facts");
  const table = getProperty(data, "markdownRemark.frontmatter.tickets.table");
  const attestation = getProperty(data, "markdownRemark.frontmatter.tickets.attestation");

  return (
    <Fragment>
      <Teaser data={teaser} />
      <Widget data={widget} />
      <Facts data={facts} />
      {/* <Table data={table} />*/}
      <Attestation data={attestation} />
      <Meta data={meta} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        tickets {
          teaser {
            title
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            marquee
            countdown {
              date
              text
              placeholder
            }
          }
          widget {
            title
            loader
          }
          facts {
            title
            items {
              label
              value
            }
          }
          table {
            title
            cols
            rows {
              groups {
                cols
              }
            }
          }
          attestation {
            title
            content
            button {
              label
              file {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
