import React from "react";
import PropTypes from "prop-types";

import { Title, getProperty } from "@btc/shared";

import { FactsMinimal } from "../common/facts-minimal";

import * as styles from "./facts.module.scss";

export const Facts = ({ data }) => {
  const title = getProperty(data, "title");
  const items = getProperty(data, "items") || [];

  return <FactsMinimal items={items} header={<Title className={styles.title}>{title}</Title>} />;
};

Facts.defaultProps = {
  data: {},
};

Facts.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};
