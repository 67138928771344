import React from "react";
import PropTypes from "prop-types";

import { Container, Section, Text, getProperty } from "@btc/shared";

import * as styles from "./facts-minimal.module.scss";

export const FactsMinimal = ({ header, items, ...props }) =>
  Array.isArray(items) && items.length > 0 ? (
    <Section {...props} size="md" color="black" noPaddingBottom={true}>
      <Container>
        {header}
        <div className={styles.wrapper}>
          {items.map((item, index) => {
            const label = getProperty(item, "label");
            const value = getProperty(item, "value");

            return (
              <div key={index} className={styles.item}>
                <div className={styles.label}>
                  <Text size="s3" type="special" transform="uppercase">
                    {label}
                  </Text>
                </div>
                <div className={styles.value}>
                  <Text size="s5" weight="bold" color="primary" className={styles.value}>
                    {value}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  ) : null;

FactsMinimal.defaultProps = {
  header: null,
  items: [],
};

FactsMinimal.propTypes = {
  header: PropTypes.element,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
