import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Text } from "@btc/shared";

import * as styles from "./marquee.module.scss";

const SPEED_SLOW = "slow";
const SPEED_MEDIUM = "normal";
const SPEED_FAST = "fast";

export const Marquee = ({ children, speed }) => {
  const wrapperClassName = useMemo(() => [styles.wrapper, styles[speed]].join(" "), [speed]);

  return (
    <Text size="s5" weight="bold" color="white" className={wrapperClassName}>
      <div className={styles.container}>
        <div className={styles.text}>{children}</div>
        <div className={styles.text}>{children}</div>
      </div>
    </Text>
  );
};

Marquee.defaultProps = {
  speed: "normal",
};

Marquee.propTypes = {
  speed: PropTypes.oneOf([SPEED_SLOW, SPEED_MEDIUM, SPEED_FAST]),
};
