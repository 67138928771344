import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container, Section, Title, TicketSmallIcon, getProperty } from "@btc/shared";

import { Marquee } from "../common/marquee";

import { Countdown } from "./countdown";

import * as styles from "./teaser.module.scss";

export const Teaser = ({ data }) => {
  const title = getProperty(data, "title");

  const marquee = getProperty(data, "marquee");
  const countdown = getProperty(data, "countdown");
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section color="black">
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Container>
            <div className={styles.container}>
              <TicketSmallIcon className={styles.icon} />
              <Title wrapper="h1" size="s6" type="gradient" className={styles.title}>
                {title}
              </Title>
            </div>
          </Container>

          <div className={styles.background}>
            <GatsbyImage image={image} alt={title} className={styles.image} />
          </div>
        </div>
        <Marquee speed="fast">
          {countdown ? <Countdown {...countdown} fallback={marquee} /> : marquee}
        </Marquee>
      </div>
    </Section>
  );
};

Teaser.defaultProps = {
  data: {},
};

Teaser.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    marquee: PropTypes.string.isRequired,
    countdown: PropTypes.shape({
      date: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
