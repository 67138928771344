import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Title, Section, Container, LoaderIcon, useLocale, getProperty } from "@btc/shared";

import * as styles from "./widget.module.scss";

export const Widget = ({ data }) => {
  const { locale } = useLocale();
  const [isLoading, setIsLoading] = useState(true);

  const title = getProperty(data, "title");
  const loader = getProperty(data, "loader");

  useEffect(() => {
    if (window.PretixWidget) {
      window.PretixWidget.buildWidgets();
      setIsLoading(false);
    } else {
      window.pretixWidgetCallback = function () {
        window.PretixWidget.addLoadListener(function () {
          setIsLoading(false);
        });
      };

      const styles = document.createElement("link");
      styles.href = "https://pretix.eu/btc/23/widget/v1.css";
      styles.rel = "stylesheet";
      styles.type = "text/css";
      document.body.appendChild(styles);

      const script = document.createElement("script");
      script.src = `https://pretix.eu/widget/v1.${locale}.js`;
      document.body.appendChild(script);
    }

    return () => {
      // pretix has no cleanup functionality and adds components
      // on each rendering of widget again, so clean up manually
      const elements = document.querySelectorAll("[class*='pretix']");
      elements.forEach((element) => element.remove());
    };
  }, [locale]);

  return (
    <Section size="sm">
      <Container>
        <Title>{title}</Title>
        {isLoading && (
          <div className={styles.loader}>
            <LoaderIcon className={styles.icon} />
            <span>{loader}</span>
          </div>
        )}
        <div id="widget" className={styles.widget}>
          <pretix-widget event="https://pretix.eu/btc/23/" skip-ssl-check="true" />
        </div>
      </Container>
    </Section>
  );
};

Widget.defaultProps = {
  data: {},
};

Widget.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    loader: PropTypes.string.isRequired,
  }).isRequired,
};
