import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export const calculateTimeDifference = (date) => {
  const end = new Date(date).getTime();
  if (isNaN(end)) {
    return false;
  }

  const diff = end - Date.now();
  if (diff < 0) {
    return false;
  }

  const days = ~~(diff / (1000 * 60 * 60 * 24));
  const hours = ~~((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = ~~((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = ~~((diff % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export const Countdown = ({ date, text, placeholder, fallback }) => {
  const [{ days, hours, minutes, seconds }, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const handler = () => {
      const remainingTime = calculateTimeDifference(date);
      if (remainingTime) {
        setRemainingTime(remainingTime);
      } else {
        setIsDone(true);
      }
    };

    handler();
    const intervalId = setInterval(handler, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  return !isDone
    ? text.replace(
        placeholder,
        [
          `${days}d`.padStart(3, "0"),
          `${hours}h`.padStart(3, "0"),
          `${minutes}m`.padStart(3, "0"),
          `${seconds}s`.padStart(3, "0"),
        ]
          .filter(Boolean)
          .join(" ")
      )
    : fallback;
};

Countdown.defaultProps = {
  date: null,
  text: "%timer%",
  fallback: null,
  placeholder: "%timer%",
};

Countdown.propTypes = {
  date: PropTypes.string.isRequired,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  fallback: PropTypes.string,
};
